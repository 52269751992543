<template>
  <div class="c-dashboard">
    <div class="c-dashboard__banner">
      <div class="o-row o-row--sm">
        <div class="o-container--lg">
          <div class="c-dashboard__banner--bg">
            <img
              src="@/assets/images/home.jpg"
              alt=""
              srcset=""
            >
          </div>
        </div>
      </div>
    </div>

    <div class="o-container">
      <div class="c-dashboard__content">
        <div class="grid">
          <div class="row">
            <div class="col-md-3">
              <div class="c-dashboard__sidemenu">
                <h3>Account</h3>
                <div class="c-dashboard__menu">
                  <ul
                    v-for="link in links"
                    :key="link.path"
                  >
                    <li :class="{ 'c-dashboard__menu--active': isActiveLink(link.routerName) }">
                      <router-link :to="{ name: link.routerName }">
                        {{ link.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <h3>{{ $route.meta.title }}</h3>
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Dashboard.controller.js"></script>
<style lang="scss" src="./Dashboard.styles.scss"></style>
