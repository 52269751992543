import { mapActions, mapGetters } from "vuex";

const createAccountLinks = routes =>
  routes
    .filter(r => r.meta && r.meta.accountNavigation)
    .map(r => {
      return {
        title: r.meta.title,
        routerName: r.name
      };
    });

export default {
  name: "Dashboard",
  data: () => ({
    example: true
  }),
  computed: {
    ...mapGetters('machine', {
      machines: 'getMachines',
      filters: 'getFilters',
      categories: 'getCategories',
      loadingState: 'getLoadingState'
    }),
  },
  methods: {
    ...mapActions("machine", {
      fetchMachines: "FETCH_DATA_MACHINES"
    }),
    isActiveLink(routeName) {
      return this.$route.name == routeName;
    }
  },
  computed: {
    links() {
      return createAccountLinks(
        this.$router.options.routes.find(r => r.name === "Account")?.children
      );
    }
  },
  created(){
    this.fetchMachines(this.filters);
  }
};
